import { Box, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import cx from "classnames";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

import OptionsGroup from "../../components/OptionsGroup";
import { loanPurposeOptions } from "../../../../commons/loan-application-constants";
import Button from "../../components/Button";
import { useStyles as useStepStyles } from "../../../../utils/loan-application.styles";

const useStyles = makeStyles((theme) => ({
  marginTop48OnMobile: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "48px",
    },
  },
}));

const LoanPurposeStep = ({ formik, handleNextStep }) => {
  const classes = useStyles();
  const stepClasses = useStepStyles();
  return (
    <Box>
      <Grid container spacing={6} xs={12} sm={6}>
        <OptionsGroup
          title="What would you like to do?"
          options={loanPurposeOptions}
          selectedValue={formik.values.loanQuiz.loanPurpose}
          onSelect={(value) =>
            formik.setFieldValue("loanQuiz.loanPurpose", value)
          }
        />
      </Grid>
      <Grid
        container
        xs={12}
        sm={6}
        justifyContent="space-between"
        classes={{
          root: cx(
            stepClasses.navigationButtonsContainer,
            classes.marginTop48OnMobile
          ),
        }}
      >
        <Button
          endIcon={<NavigateNextIcon />}
          onClick={handleNextStep}
          disabled={formik.values.loanQuiz.loanPurpose === ""}
        >
          Next
        </Button>
      </Grid>
    </Box>
  );
};

export default LoanPurposeStep;
